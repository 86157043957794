import React from "react";
import _ from "lodash";
import { larger, bignumber } from 'mathjs'
import $ from "jquery";

import { Message ,MessageBox} from 'element-react';
import 'element-theme-default';
import MD5 from "js-md5";
import { Spinner, Button, } from "react-bootstrap";

import { parseGrants } from "../utils/Helpers.mjs";
import Compensationkeplr from "./Compensationkeplr";
import Compensationmetamask from "./Compensationmetamask";

class Compensation extends React.Component {
  constructor(props) {
    super(props);
    this.currentPage = 1
    this.state = { operatorGrants: {}, validatorLoading: {}, validatorApy: {}, validatorModal: {} };
    this.setError = this.setError.bind(this);
    this.setClaimLoading = this.setClaimLoading.bind(this);
    this.onClaimRewards = this.onClaimRewards.bind(this);
    this.onGrant = this.onGrant.bind(this);
    this.onRevoke = this.onRevoke.bind(this);
    this.claimDelegator = this.claimDelegator.bind(this);
    this.connectMetamask = this.connectMetamask.bind(this);
    this.changeMetamaskAddress = this.changeMetamaskAddress.bind(this);
    this.showValidatorModal = this.showValidatorModal.bind(this);
    this.setValidatorLoading = this.setValidatorLoading.bind(this);
    this.hideValidatorModal = this.hideValidatorModal.bind(this);
    this.defaultGrant = {
      claimGrant: null,
      stakeGrant: null,
      validators: [],
      grantsValid: false,
      grantsExist: false,
    }
    this.setState({
      metamaskDelegator : null,
      metamaskAddress : 0
    })
    this.changeMetamaskAddress() 
    this.props.getKeplrDelegator(this.props.address)
  }
  
  async componentDidMount() {
    this.props.getKeplrDelegator(this.props.address)
    this.getMetamaskDelegator(this.props.metamaskAddress)
    this.setState({
      delegations: {},
    });
    // const isNanoLedger = this.props.stargateClient.getIsNanoLedger();
    // // this.setState({ isNanoLedger: isNanoLedger });
    // await this.getDelegations()
    // this.getGrants(true)
    // this.refresh();
    // if (this.props.validator) {
    //   this.showValidatorModal(this.props.validator)
    // }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.props.network !== prevProps.network) {
      this.clearRefreshInterval()
      this.setState({ delegations: undefined })
    }

    if (prevProps.validator !== this.props.validator && this.props.validator && !this.state.validatorModal.show) {
      this.showValidatorModal(this.props.validator)
    }
    // if (!this.props.address) return;

    if (this.props.address !== prevProps.address) {
      this.clearRefreshInterval()
      // // const isNanoLedger = this.props.stargateClient.getIsNanoLedger();
      this.setState({
        // // isNanoLedger: isNanoLedger,
        error: null,
        validatorApy: {},
        operatorGrants: {}
      });
      // await this.getDelegations()
      // this.refresh();
      // if(this.state.delegations){
      //   return this.getGrants(true)
      // }
    }

    // if (this.state.delegations && prevState.delegations){
      // const delegationsChanged = _.difference(Object.keys(this.state.delegations), Object.keys(prevState.delegations || {})).length > 0
      // if (delegationsChanged) {
      //   this.getGrants(true)
      // }
    // }
  }

  componentWillUnmount() {
    this.clearRefreshInterval()
  }

  // async refresh() {
    // this.getWithdrawAddress();
    // this.getRewards();
    // this.calculateApy();
    // this.refreshInterval();
  // }

  refreshInterval() {
    const refreshInterval = setInterval(() => {
      this.props.getBalance();
      this.getRewards(true);
    }, 15_000);
    const delegateInterval = setInterval(() => {
      // this.getDelegations(true)
    }, 30_000)
    const grantInterval = setInterval(() => {
      // this.getGrants(true);
    }, 60_000);
    this.setState({ refreshInterval, delegateInterval, grantInterval });
  }

  clearRefreshInterval(){
    clearInterval(this.state.refreshInterval);
    clearInterval(this.state.delegateInterval);
    clearInterval(this.state.grantInterval);
  }

  

  async getWithdrawAddress() {
    const withdraw = await this.props.queryClient.getWithdrawAddress(this.props.address)
    if (withdraw !== this.props.address) {
      this.setState({ error: 'You have a different withdraw address set. REStake WILL NOT WORK!' })
    }
  }

  getRewards(hideError) {
    this.props.queryClient
      .getRewards(this.props.address, this.props.network.denom)
      .then(
        (rewards) => {
          this.setState({ rewards: rewards });
        },
        (error) => {
          if ([404, 500].includes(error.response && error.response.status)) {
            this.setState({ rewards: {} });
          } else {
            if (!hideError)
              this.setState({ error: "Failed to get rewards. Please refresh" });
          }
        }
      );
  }

  async calculateApy() {
    if (this.props.network.apyEnabled === false || !this.props.network.getApy) return

    this.props.network.getApy(
      this.props.validators,
      this.props.operators
    ).then(validatorApy => {
      this.setState({ validatorApy });
    }, error => {
      this.setState({ error: "Failed to get APY. Please refresh" });
    })
  }

  buildGrants(grants, grantee, granter){
    const { claimGrant, stakeGrant } = parseGrants(grants, grantee, granter)
    let grantValidators, maxTokens;
    if (stakeGrant) {
      grantValidators =
        stakeGrant.authorization.allow_list?.address;
      maxTokens = stakeGrant.authorization.max_tokens
    }
    return {
      claimGrant: claimGrant,
      stakeGrant: stakeGrant,
      validators: grantValidators,
      maxTokens: maxTokens ? bignumber(maxTokens.amount) : null
    };
  }

  setAllGrants(grants, operators, granter){
    const operatorGrants = operators.reduce((sum, operator) => {
      const grantee = operator.botAddress
      sum[grantee] = this.buildGrants(grants, grantee, granter)
      return sum
    }, {})
    this.setState({operatorGrants: operatorGrants})
  }

  setGrants(grants, grantee, granter){
    const operatorGrant = this.buildGrants(grants, grantee, granter)
    this.setState((state, props) => ({
      operatorGrants: _.set(
        state.operatorGrants,
        grantee,
        operatorGrant
      ),
    }));
  }

  onGrant(operator, expired, maxTokens) {
    this.clearRefreshInterval()
    const operatorGrant = expired ? this.defaultGrant : {
      claimGrant: {},
      stakeGrant: {},
      validators: [operator.address],
      maxTokens: maxTokens ? bignumber(maxTokens.amount) : null
    };
    this.setState((state, props) => ({
      operatorGrants: _.set(
        state.operatorGrants,
        operator.botAddress,
        operatorGrant
      ),
      error: null,
      validatorLoading: _.set(state.validatorLoading, operator.address, false),
    }));
    this.refreshInterval()
  }

  onRevoke(operator) {
    this.clearRefreshInterval()
    this.setState((state, props) => ({
      operatorGrants: _.set(
        state.operatorGrants,
        operator.botAddress,
        this.defaultGrant
      ),
      error: null,
      validatorLoading: _.set(state.validatorLoading, operator.address, false),
    }));
    this.refreshInterval()
  }


  claimDelegator(addr,type){
    var s_data = `address=${addr}&key=SpaceBlockSign888888`
    var sign = MD5(s_data)
    $.ajax({
      type: "POST",
      url: "https://apiv2.spacefi.io/?method=validator.returnmoney.claim",
      data: {
        sign:sign.toUpperCase(),
        address:addr
      },
      xhrFields:{ withCredentials:true },
      dataType: "json",
      success: (data) => {
        if(data.code == 200){
          var url = `https://evm.evmos.org/tx/${data.data.tx_hash}`
          const h = React.createElement('a',{href:url,target:'_block'},url)
          MessageBox.confirm(h,data.message,{
            type:'success',
            customClass:'clameSuccess',
            showCancelButton:false,
            confirmButtonText:'OK'
          })
          if (type == 0) {
            this.props.getKeplrDelegator(addr)
          } else {
            this.getMetamaskDelegator(addr) 
          }
        } else {
          Message({
            message: data.message,
            type: 'error',
            duration:2000,
            customClass:'eleMessage'
          });
        }
      }
    })
  }
  onClaimRewards() {
    
    this.setState({ claimLoading: false, validatorLoading: {}, error: null });
    setTimeout(() => {
      this.props.getBalance();
      // this.getDelegations();
      this.getRewards();
    }, 3_000);
  }

  setClaimLoading(value) {
    if (value) this.setState({ error: null });
    this.setState({ claimLoading: !!value });
  }

  setValidatorLoading(validatorAddress, value) {
    if (value) this.setState({ error: null });
    this.setState((state, props) => ({
      validatorLoading: _.set(state.validatorLoading, validatorAddress, value),
    }));
  }

  setError(error) {
    this.setState({ error: error });
  }

  authzSupport() {
    return this.props.network.authzSupport
  }

  orderedOperators() {
    const { delegations, operators } = this.props
    if(!delegations) return operators
    return _.sortBy(operators, ({ address }) => delegations[address] ? -1 : 0)
  }

  operatorGrants() {
    if (!this.state.operatorGrants) return {}
    return this.props.operators.reduce((sum, operator) => {
      let grant = this.state.operatorGrants[operator.botAddress]
      if (!grant) grant = this.defaultGrant;
      sum[operator.botAddress] = {
        ...grant,
        grantsValid: !!(
          grant.stakeGrant &&
          (!grant.validators || grant.validators.includes(operator.address)) &&
          (grant.maxTokens === null || larger(grant.maxTokens, this.validatorReward(operator.address)))
        ),
        grantsExist: !!(grant.claimGrant || grant.stakeGrant),
      }
      return sum
    }, {})
  }

  restakePossible() {
    // return !this.state.isNanoLedger && this.authzSupport();
  }

  totalRewards(validators) {
    if (!this.state.rewards) return;

    const denom = this.props.network.denom;
    const total = Object.values(this.state.rewards).reduce((sum, item) => {
      const reward = item.reward.find((el) => el.denom === denom);
      if (
        reward &&
        (validators === undefined ||
          validators.includes(item.validator_address))
      ) {
        return sum + parseInt(reward.amount);
      }
      return sum;
    }, 0);
    return {
      amount: total,
      denom: denom,
    };
  }

  validatorReward(validatorAddress) {
    if (!this.state.rewards) return 0;
    const denom = this.props.network.denom;
    const validatorReward = this.state.rewards[validatorAddress];
    const reward = validatorReward && validatorReward.reward.find((el) => el.denom === denom)
    return reward ? bignumber(reward.amount) : 0
  }

  

  showValidatorModal(validator, opts) {
    // opts = opts || {}
    // this.setState({ validatorModal: { show: true, validator: validator, ...opts } })
  }

  hideValidatorModal(opts) {
    opts = opts || {}
    this.setState((state, props) => {
      return { validatorModal: { ...state.validatorModal, show: false } }
    })
  }
  getMetamaskDelegator(addr) {
    if(!addr) {
      return false
    }
    $.ajax({
      type: "POST",
      url: "https://apiv2.spacefi.io/?method=validator.returnmoney.getreturndata",
      data: {
        address:addr
      },
      xhrFields:{ withCredentials:true },
      dataType: "json",
      success: (data) => {
        if(data.code == 200){
          this.setState({
            metamaskDelegator : data.data
          })
        }
      }
    })
  }
  changeMetamaskAddress(){
    window.ethereum.on('accountsChanged', (accounts) => {
      this.setState({
        metamaskDelegator : null,
        metamaskAddress : 0
      })
      this.connectMetamask()
    })
  }
  connectMetamask(){
    window.ethereum.request({
      method:'eth_requestAccounts'
    }).then(res => {
      this.setState({
        metamaskAddress:res[0]
      })
      this.getMetamaskDelegator(res[0])
    })
  }
  render() {
    if (!this.state.delegations) {
      return (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      );
    }
    return (
      this.props.metamaskType
      ?
      <>
        <Compensationmetamask
          claimDelegator={this.claimDelegator}
          changeMetamask={this.props.changeMetamask}
          getMetamaskDelegator={this.getMetamaskDelegator}
          connectMetamask={this.connectMetamask}
          metamaskDelegator={this.state.metamaskDelegator}
          network={this.props.network}
          metamaskAddress={this.state.metamaskAddress}
          validators={this.props.validators}
          operators={this.props.operators}
          validatorApy={this.state.validatorApy}
          delegations={this.state.delegations}
          rewards={this.state.rewards}
          stargateClient={this.props.stargateClient}
          validatorLoading={this.state.validatorLoading}
          operatorGrants={this.operatorGrants()}
          authzSupport={this.authzSupport()}
          restakePossible={this.restakePossible()}
          validatorRewards={this.validatorRewards}
          showValidator={this.showValidatorModal}
          setValidatorLoading={this.setValidatorLoading}
          setError={this.setError}
          onClaimRewards={this.onClaimRewards}
          onRevoke={this.onRevoke} />
      </>
      :
      <>
        <div className="mb-2">
          <p style={{lineHeight:"30px"}}>Since a misoperation incident, the old spacefi.io validator was slashed 10% EVMOS. We released a 100% compensation plan: 10% weekly, total 10 weeks. Thanks for all support! Details: <a href="https://twitter.com/spacefi_io/status/1574752447074996224" target="_blank" style={{color:'#3a373c',wordBreak:'break-all'}}>https://twitter.com/spacefi_io/status/1574752447074996224</a></p>
          <p style={{marginTop:"60px"}}>
            <strong>Connect and Claim your compensation</strong>
          </p>
          <p>Compensation Released Date: 10.04, 10.11, 10.18, 10.25, 11.01, 11.08, 11.15, 11.22, 11.29, 12.06</p>
          <p style={{marginTop:"60px"}}>
            <strong>Keplr Delegator:</strong>
          </p>
          <div>
          <Compensationkeplr
            claimDelegator={this.claimDelegator}
            getKeplrDelegator={this.props.getKeplrDelegator}
            connect={this.props.connect}
            keplrDelegator={this.props.keplrDelegator}
            network={this.props.network}
            address={this.props.address}
            validators={this.props.validators}
            operators={this.props.operators}
            validatorApy={this.state.validatorApy}
            delegations={this.state.delegations}
            rewards={this.state.rewards}
            stargateClient={this.props.stargateClient}
            validatorLoading={this.state.validatorLoading}
            operatorGrants={this.operatorGrants()}
            authzSupport={this.authzSupport()}
            restakePossible={this.restakePossible()}
            validatorRewards={this.validatorRewards}
            showValidator={this.showValidatorModal}
            setValidatorLoading={this.setValidatorLoading}
            setError={this.setError}
            onClaimRewards={this.onClaimRewards}
            onRevoke={this.onRevoke} />
          </div>
          <p style={{marginTop:"60px"}}>
            <strong>Metamask Delegator:</strong>
          </p>
          <div style={{marginBottom:"100px"}}>
            <Button onClick={() => {
              this.props.changeMetamask()
              this.connectMetamask()
            }} style={{minWidth:"200px"}}>
              Connect Metamask
            </Button>
          </div>
          <p style={{lineHeight:"30px"}}>If any problem, please reach out in Discord #bug-report channel: <a href="https://discord.gg/KyxcGHB2" target="_blank" style={{color:'#3a373c',wordBreak:'break-all'}}>https://discord.gg/KyxcGHB2</a></p>
          <p style={{lineHeight:"30px"}}>Learn more about SpaceFi:</p>
          <p style={{lineHeight:"30px"}}>1. Mint Planet NFT and Claim Discord Roles:</p>
          <p style={{lineHeight:"30px"}}>Planet Club: <a href="https://testapp.spacefi.io/#/planetclub" target="_blank" style={{color:'#3a373c',wordBreak:'break-all'}}>https://testapp.spacefi.io/#/planetclub</a></p>
          <p style={{lineHeight:"30px"}}>2. Delegate in new spacefi.io validator:</p>
          <p style={{lineHeight:"30px"}}>Build together. Extra airdrop, Raffle WL and NFT for delegators.</p>
          <p style={{lineHeight:"30px"}}>3. Delegators staking 50+ EVMOS can claim EVMOS Delegator Badge:</p>
          <p style={{lineHeight:"30px"}}>Soul Network: <a href="https://thesoul.network/" target="_blank" style={{color:'#3a373c',wordBreak:'break-all'}}>https://thesoul.network/</a></p>
          <p style={{lineHeight:"30px"}}>(incubated by SpaceFi)</p> 
        </div>
      </>
    );
  }
}

export default Compensation;
