import React, { useState, useEffect , useReducer} from 'react';
import _ from 'lodash'
import FuzzySearch from 'fuzzy-search'
import About from './Aboutdelegatorcenter'
import MD5 from "js-md5";
import $ from "jquery";
import { Message } from 'element-react';
import 'element-theme-default';
import React from 'react';
import {
  Table,
} from 'react-bootstrap'
import { XCircle } from "react-bootstrap-icons";

function Validators(props) {
  const {  network, validators, operators, delegations, operatorGrants, integra, address, connect} = props
  const [filter, setFilter] = useState({keywords: '', status: 'active', group: 'delegated'})
  const [results, setResults] = useState([])
  const [state, setState] = useReducer(
    (state, newState) => ({...state, ...newState}),
    {loading: true, networks: {}, operators: [], validators: {}, showAboutDelegator: false, searchValue:'',discordName:''}
  )
  useEffect(() => {
    let filtered = filteredValidators(validators, filter)
    let group = filter.group
    while(filtered.length < 1 && group !== 'all'){
      group = group === 'delegated' ? 'operators' : 'all'
      filtered = filteredValidators(validators, {...filter, group})
      if(filtered.length > 0 || group === 'all'){
        return setFilter({ ...filter, group })
      }
    }
    setResults(filtered)
  }, [validators, operators, delegations, operatorGrants, filter]);
  
  function sortValidators(validators){
    validators = _.sortBy(validators, ({ operator_address: address }) => {
      const delegation = delegations && delegations[address]
      return 0 - (delegation?.balance?.amount || 0)
    });
    return _.sortBy(validators, ({ operator_address: address }) => {
      if(network.data.ownerAddress === address) return -5

      const delegation = delegations && delegations[address]
      const operator = operators && operators.find(el => el.address === address)

      if (delegation) {
        return operator ? -2 : -1
      } else {
        return operator ? 0 : 1
      }
    });
  }

  function changeDiscordName(event){
    setState({discordName:event.target.value})
  }

  function discordRole(){
    if(!address){
      props.connect(true)
      return false
    }
    var obj = {
      discord_account:state.discordName,
      address:address
    }
    var s_data = sort_ascii(obj)+'&key=SpaceBlockSign888888'
    var sign = MD5(s_data)
    $.ajax({
      type: "POST",
      url: "https://apiv2.spacefi.io/?method=web.discord.editdiscordpledgerole",
      data: {
        sign:sign.toUpperCase(),
        discord_account:state.discordName,
        address:address
      },
      xhrFields:{ withCredentials:true },
      dataType: "json",
      success: function(data) {
        if (data) {
          Message({
            message: data.data.message,
            type: data.data.message == 'Club account is linked successfully' ? 'success' : 'error',
            duration:2000,
            customClass:'eleMessage'
          });
        } else {
          Message({
            message: 'Your role is claimed successfully.',
            type: 'success',
            duration:2000,
            customClass:'eleMessage'
          });
        }
        // Message({
        //   message: data.data.message,
        //   type: data.data.message == 'Club account is linked successfully' ? 'success' : 'error',
        //   duration:2000,
        //   customClass:'eleMessage'
        // });
      },
      error: function(err) {
        Message({
          message: 'Your role is claimed successfully.',
          type: 'success',
          duration:2000,
          customClass:'eleMessage'
        });
      }
    });
  }

  function sort_ascii(obj) {
    let arr = new Array();
    let num = 0;
    for (let i in obj) {
        arr[num] = i;
        num++;
    }
    let sortArr = arr.sort();
    let str = '';             
    for (let i in sortArr) {
        str += sortArr[i] + '=' + obj[sortArr[i]] + '&';
    }
    let char = '&'
    str = str.replace(new RegExp('^\\' + char + '+|\\' + char + '+$', 'g'), '');

    return str;
    //return sortObj;
  }

  function filterValidators(event){
    setState({searchValue:event.target.value})
    props.getIntegraList(null,event.target.value)
  }

  function filteredValidators(validators, filter){
    let searchResults
    if (props.exclude){
      searchResults = Object.values(_.omit(validators, props.exclude))
    }else{
      searchResults = Object.values(validators)
    }
    const { keywords, status, group } = filter

    if(status){
      searchResults = searchResults.filter(result => {
        if (status === 'active') {
          return result.status === 'BOND_STATUS_BONDED'
        } else if (status === 'inactive') {
          return result.status !== 'BOND_STATUS_BONDED'
        } else {
          return true
        }
      })
    }

    searchResults = filterByGroup(searchResults, group)

    if (!keywords || keywords === '') return sortValidators(searchResults)

    const searcher = new FuzzySearch(
      searchResults, ['description.moniker'],
      { sort: true }
    )

    return searcher.search(keywords)
  }

  function filterByGroup(validators, group){
    switch (group) {
      case 'delegated':
        validators = validators.filter(({operator_address: address}) => {
          return delegations && delegations[address]
        })
        break;
      case 'operators':
        validators = validators.filter(({operator_address: address}) => {
          return operators && operators.find(el => el.address === address)
        })
        break;
    }
    return validators
  }

  function renderIntegra(validator,key) {
    return (
      <tr key={key}>
        <td>{validator.ranking}</td>
        <td>{validator.address}</td>
        <td>{validator.addresss_erc20}</td>
        <td className="text-center">{validator.staked_amount}</td>
        <td className="text-center">{validator.staked_time}</td>
        <td className="text-center">{validator.number_votes}</td>
        <td className="text-center">{validator.delegetor_points}</td>
      </tr>
    );
  }

  return (
    <>
      <div className={`${!props.modal && 'd-lg-flex'} d-none mx-auto justify-content-center align-self-center fs-6small`}>
        Delegator points is an evaluation of contribution, based on your staked amount, time and governance participation. <span style={{textDecoration:'underline',cursor:'pointer'}} onClick={() => {setState({showAboutDelegator: true})}}>Details</span> 
        <div className="d-flex justify-content-end" style={{marginLeft:'30px'}}>
          My Delegator Points: {integra ? integra : '0.0'}
        </div>
      </div>
      
      <div className="d-none d-sm-flex flex-wrap justify-content-center align-items-center mb-3 mt-3">
        <div className="" style={{margin:'0 30px'}}>
          <div className="input-group">
            <input className="form-control border-right-0 border" onChange={filterValidators} value={state.searchValue} type="text" placeholder="Search Address" style={{width: 400}} />
            <span className="input-group-append">
              <button className="btn btn-light text-dark border-left-0 border" type="button" onClick={() => {
                  setState({searchValue:''})
                  props.getIntegraList(1)
                }}>
                <XCircle />
              </button>
            </span>
          </div>
        </div>
        
        <div style={{height:"35px",width:"1px",background:"#dee2e6"}}></div>

        <div className="d-flex justify-content-end" style={{margin:'0 30px'}}>
          <div className="input-group">
            <input className="form-control border-right-0 border" onChange={changeDiscordName} value={state.discordName} type="text" placeholder="Enter username including the number: David#1024" style={{width: 400}} />
            <span className="input-group-append">
              <button className="btn btn-light text-dark border-left-0 border" type="button" onClick={discordRole}>
                Claim Discord Role
              </button>
            </span>
          </div>
        </div>
      </div>

      <div className="d-flex flex-column justify-content-center align-items-center d-sm-none mb-3">
        <div className={`fs-6small text-left fs-0_8 mt-2 mb-2`}>
          Delegator points is an evaluation of contribution, based on your staked amount, time and governance participation. <span style={{textDecoration:'underline',cursor:'pointer'}} onClick={() => {setState({showAboutDelegator: true})}}>Details</span> 
        </div>
        <div className="align-self-end ">
          My Delegator Points: {integra}
        </div>

        <div className="" style={{width:'100%',marginTop:'20px'}}>
          <div className="input-group">
            <input className="form-control border-right-0 border" onChange={filterValidators} value={state.searchValue} type="text" placeholder="Search Address" />
            <span className="input-group-append">
              <button className="btn btn-light text-dark border-left-0 border" type="button" onClick={() => {
                  setState({searchValue:''})
                  props.getIntegraList(1)
                }}>
                <XCircle />
              </button>
            </span>
          </div>
        </div>
        <div className="" style={{width:'100%',marginTop:'10px'}}>
          <div className="input-group">
            <input className="form-control border-right-0 border" onChange={changeDiscordName} value={state.discordName} type="text" placeholder="Enter username" />
            <span className="input-group-append">
              <button className="btn btn-light text-dark border-left-0 border" type="button" onClick={discordRole}>
                Claim Discord Role
              </button>
            </span>
          </div>
          <p style={{fontSize:'14px',color:'#868b92'}}>Enter username including the number: David#1024</p>
        </div>
        
      </div>

      <About show={state.showAboutDelegator}  onHide={() => {setState({showAboutDelegator: false})}}/>
      {results.length > 0 &&
        <div style={{overflowX:'scroll'}}>
          <Table className="align-middle table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th colSpan={1}>EVMOS Address</th>
                <th colSpan={1}>ERC20 Address</th>
                <th className="text-center text-nowrap" colSpan={1}>Stakered Amount</th>
                <th className="text-center text-nowrap" colSpan={1}>Staked Days</th>
                <th className="text-center text-nowrap" colSpan={1}>Number of Voting</th>
                <th className="text-center text-nowrap" colSpan={1}>Delegator Points</th>
              </tr>
            </thead>
            <tbody>
              { props.integraList && 
                props.integraList.map((item,key) => renderIntegra(item,key))
              }
            </tbody>
            
          </Table>
        </div>
      }
      {results.length < 1 &&
        <p className="text-center my-5"><em>No validators found</em></p>
      }
    </>
  )
}

export default Validators;
