import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import React from 'react'
import _ from 'lodash'
import AlertMessage from './AlertMessage'
import NetworkSelect from './NetworkSelect'
import Delegations from './Delegations';
import Coins from './Coins'
import About from './About'
import $ from "jquery";
import axios from "axios";
import { MsgGrant, MsgRevoke } from "cosmjs-types/cosmos/authz/v1beta1/tx.js";
import {
  Container,
  Button,
  Badge,
  Dropdown,
  ButtonGroup,
  Navbar,
  Nav
} from 'react-bootstrap';
import {
  Droplet,
  DropletFill,
  DropletHalf,
  Coin,
  Inboxes,
  Gift,
  Stars,
  Star,
  Person,
} from 'react-bootstrap-icons'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import GitHubButton from 'react-github-btn'
import Logo123 from '../assets/123.png'
import Logo from '../assets/logo.png'
import Logo2x from '../assets/logo@2x.png'
import Logo3x from '../assets/logo@3x.png'
import LogoWhite from '../assets/logo-white.png'
import LogoWhite2x from '../assets/logo-white@2x.png'
import LogoWhite3x from '../assets/logo-white@3x.png'
import PoweredByAkash from '../assets/powered-by-akash.svg'
import PoweredByAkashWhite from '../assets/powered-by-akash-white.svg'
import TooltipIcon from './TooltipIcon';
import Governance from './Governance';
import Networks from './Networks';
import Delegatorcenter from './Delegatorcenter';
import Compensation from './Compensation';
import axios from 'axios';

const directoryDomain = process.env.DIRECTORY_DOMAIN || 'cosmos.directory'

class App extends React.Component {
  constructor(props) {
    super(props);
    const favouriteJson = localStorage.getItem('favourites')
    this.state = {
      favourites: favouriteJson ? JSON.parse(favouriteJson) : [],
      metamaskType: false,
      keplrDelegator : null,
    }
    this.connect = this.connect.bind(this);
    this.disconnect = this.disconnect.bind(this);
    this.connectKeplr = this.connectKeplr.bind(this);
    this.showNetworkSelect = this.showNetworkSelect.bind(this);
    this.changeMetamask = this.changeMetamask.bind(this);
    this.getBalance = this.getBalance.bind(this)
    this.toggleFavourite = this.toggleFavourite.bind(this);
    this.getKeplrDelegator = this.getKeplrDelegator.bind(this)
  }

  async componentDidMount() {
    this.connectKeplr()
    window.addEventListener("load", this.connectKeplr)
    window.addEventListener("keplr_keystorechange", this.connect)
  }

  async componentDidUpdate(prevProps, prevState) {
    if(!this.props.network) return
    if (this.state.keplr != prevState.keplr) {
      this.connect()
    } else if (this.props.network !== prevProps.network) {
      this.setState({ balance: undefined, address: undefined })
      this.connect()
    }
  }

  componentWillUnmount() {
    window.removeEventListener("load", this.connectKeplr)
    window.removeEventListener("keplr_keystorechange", this.connect)
  }

  showNetworkSelect() {
    this.setState({ showNetworkSelect: true })
  }

  changeMetamask() {
    this.setState({ metamaskType: !this.state.metamaskType })
    
  }

  connected() {
    return this.props.network?.connected && Object.values(this.props.validators).length > 0
  }

  connectKeplr() {
    if (this.state.keplr && !window.keplr) {
      this.setState({ keplr: false })
    } else if (!this.state.keplr && window.keplr) {
      this.setState({ keplr: true })
    }
  }

  disconnect(){
    localStorage.removeItem('connected')
    this.setState({
      address: null,
      balance: null,
      queryClient: null,
      stargateClient: null
    })
  }

  async connect(manual) {
    if (this.props.network && !this.connected()) {
      return this.setState({
        error: 'Could not connect to any available API servers'
      })
    }

    if(localStorage.getItem('connected') !== '1'){
      if(manual){
        localStorage.setItem('connected', '1')
      }else{
        return
      }
    }

    const { network } = this.props
    if(!network) return

    const chainId = network.chainId

    try {
      if (window.keplr) {
        if (network.gasPricePrefer) {
          window.keplr.defaultOptions = {
            sign: { preferNoSetFee: true }
          }
        }
        await window.keplr.enable(chainId);
      }
    } catch (e) {
      await this.suggestChain(network)
    }
    if (window.getOfflineSigner) {
      try {
        const offlineSigner = await window.getOfflineSignerAuto(chainId)
        const key = await window.keplr.getKey(chainId);
        const stargateClient = await network.signingClient(offlineSigner, key, network.gasPricePrefer)
        const accounts = await offlineSigner.getAccounts();
        const address = accounts[0].address;
        stargateClient.registry.register("/cosmos.authz.v1beta1.MsgGrant", MsgGrant)
        stargateClient.registry.register("/cosmos.authz.v1beta1.MsgRevoke", MsgRevoke)
        this.setState({
          address: address,
          accountName: key.name,
          stargateClient: stargateClient,
          queryClient: network.queryClient,
          error: false
        })
        this.getKeplrDelegator(address)
        this.getBalance()
        this.getIntegra()
      } catch (e) {
        return this.setState({
          error: 'Failed to connect to signing client: ' + e.message,
          loading: false
        })
      }
    }
  }
  getKeplrDelegator(addr) {
    if(addr != 0) {
      $.ajax({
        type: "POST",
        url: "https://apiv2.spacefi.io/?method=validator.returnmoney.getreturndata",
        data: {
          address:addr
        },
        xhrFields:{ withCredentials:true },
        dataType: "json",
        success: (data) => {
          if(data.code == 200){
            this.setState({
              keplrDelegator : data.data
            })
          }
        }
      })
    }
  }

  suggestChain(network) {
    if (!window.keplr) return
    const currency = {
      coinDenom: network.symbol,
      coinMinimalDenom: network.denom,
      coinDecimals: network.decimals,
      coinGeckoId: network.coinGeckoId
    }
    return window.keplr.experimentalSuggestChain({
      rpc: network.rpcUrl,
      rest: network.restUrl,
      chainId: network.chainId,
      chainName: network.prettyName,
      stakeCurrency: currency,
      bip44: { coinType: network.slip44 },
      walletUrlForStaking: "https://restake.app/" + network.name,
      bech32Config: {
        bech32PrefixAccAddr: network.prefix,
        bech32PrefixAccPub: network.prefix + "pub",
        bech32PrefixValAddr: network.prefix + "valoper",
        bech32PrefixValPub: network.prefix + "valoperpub",
        bech32PrefixConsAddr: network.prefix + "valcons",
        bech32PrefixConsPub: network.prefix + "valconspub"
      },
      currencies: [currency],
      feeCurrencies: [currency],
      gasPriceStep: network.gasPriceStep
    })
  }

  toggleFavourite(network){
    const { favourites } = this.state
    let newFavourites
    if(favourites.includes(network.path)){
      newFavourites = favourites.filter(el => el !== network.path)
    }else{
      newFavourites = [...favourites, network.path]
    }
    localStorage.setItem('favourites', JSON.stringify(newFavourites))
    this.setState({ favourites: newFavourites })
  }

  async getBalance() {
    this.state.queryClient.getBalance(this.state.address, this.props.network.denom).then(
      (balance) => {
        this.setState({
          balance: balance
        })
      }
    )
  }

  async getIntegra(){
    axios.get("https://api.test.spacefi.io/web/home/getrowintegra?delegator_address_evmos=" + this.state.address).then((res) => {
      this.setState({
        integra: res.data.data ? res.data.data[0].integral : '0.0'
      })
    })
  }
  
  setCopied() {
    this.setState({ copied: true })
    setTimeout(() => {
      this.setState({ copied: false })
    }, 2000)
  }

  themeIcon() {
    const { theme, themeChoice, themeDefault, setThemeChoice } = this.props
    let icon, switchTo
    let iconProps = {
      size: '1.4em',
      className: 'me-3',
      role: 'button',
      onClick: () => setThemeChoice(switchTo)
    }
    if (themeChoice === 'auto') {
      icon = <DropletHalf {...iconProps} />
      switchTo = theme === 'dark' ? 'light' : 'dark'
    } else {
      icon = themeChoice === 'dark' ? <DropletFill {...iconProps} /> : <Droplet {...iconProps} />
      switchTo = themeDefault !== theme ? 'auto' : theme === 'dark' ? 'light' : 'dark'
    }
    const tooltip = `Switch to ${switchTo} mode`
    return (
      <TooltipIcon icon={icon} tooltip={tooltip} placement="left" />
    )
  }

  render() {
    return (
      <Container>
        <header className="">
          <div className="d-flex justify-content-between py-3 border-bottom">
            <div className="logo d-flex align-items-center text-reset text-decoration-none">
              <span onClick={() => window.open(`https://testapp.spacefi.io/#/home`, "_blank")} role="button" className="text-reset text-decoration-none">
                {this.props.theme === 'light'
                  ? (
                    <img src={Logo} srcSet={`${Logo2x} 2x, ${Logo3x} 3x`} alt="REStake" />
                  ) : (
                    <img src={LogoWhite} srcSet={`${LogoWhite2x} 2x, ${LogoWhite3x} 3x`} alt="REStake" />
                  )}
              </span>
            </div>
            <div className="d-flex align-items-center text-reset text-decoration-none">
              <p className="lead fs-6small text-center m-0 px-5 d-lg-block d-none">
                REStake allows validators to <strong onClick={() => this.setState({ showAbout: true })} className="text-decoration-underline" role="button">auto-compound</strong> your {this.props.network && <strong onClick={this.showNetworkSelect} className="text-decoration-underline" role="button">{this.props.network.prettyName}</strong>} staking rewards
              </p>
            </div>
            <div className="align-items-center text-reset text-decoration-none">
              <Nav activeKey={this.props.active} onSelect={(e) => this.props.setActive(e)}>
                <Nav.Link eventKey="delegatorcenter" style={{padding:'0.5rem 0rem'}}>
                  <p className="lead fs-1_2 text-left m-0 d-lg-block d-none color924cff">Delegator Points: {this.state.integra ? this.state.integra : '0.0'}</p>
                </Nav.Link>
              </Nav>
            </div>
            <div className="d-flex align-items-center text-reset text-decoration-none">
              {this.themeIcon()}
              <NetworkSelect show={this.state.showNetworkSelect} onHide={() => { this.setState({ showNetworkSelect: false }) }} networks={this.props.networks}
                network={this.props.network}
                validators={this.props.validators}
                changeNetwork={this.props.changeNetwork} />
            </div>
          </div>
          {
            this.state.metamaskType
            ?
            <>
            </>
            :
            <>
              <div className="justify-content-between border-bottom d-none d-sm-flex">
                <Navbar className={`navbar navbar-expand ${this.props.theme === 'dark' ? 'navbar-dark' : 'navbar-light'}`}>
                  <div className="justify-content-center">
                    <Nav activeKey={this.props.active} onSelect={(e) => this.props.setActive(e)}>
                          <div className="nav-item pe-2 border-end">
                            <Nav.Link eventKey="networks">
                              <Stars className="mb-1 me-1" /> Explore
                            </Nav.Link>
                          </div>
                      {this.props.network && (
                        <>
                          <div className="nav-item border-end">
                            <Nav.Link eventKey="delegations">
                              <Coin className="mb-1 me-1" /> Delegate
                            </Nav.Link>
                          </div>
                          <div className="nav-item border-end">
                            <Nav.Link eventKey="governance">
                              <Inboxes className="mb-1 me-1" /> Govern
                            </Nav.Link>
                          </div>
                          <div className="nav-item border-end">
                            <Nav.Link eventKey="delegatorcenter">
                              <Person className="mb-1 me-1" /> Delegator Center
                            </Nav.Link>
                          </div>
                          <div className="nav-item border-end">
                            <Nav>
                              <a href={`https://twitter.com/spacefi_io/status/1536302354495987712`} target="_blank" className="nav-link text-decoration-none d-block" >
                                <Star className="mb-1 me-1" /> Space Incentive
                              </a>
                            </Nav>
                          </div> 
                          <div className="nav-item ">
                            <Nav.Link eventKey="compensation">
                            {/* <Gift className="mb-1 me-1" /> Compensation */}
                              <img style={{width:'16px',height:'16px',margin:'0 4px 4px 0'}} src={Logo123} /> Compensation
                            </Nav.Link>
                          </div>
                        </>
                      )}
                    </Nav>
                  </div>
                </Navbar>
                <nav className={`navbar navbar-expand-lg ${this.props.theme === 'dark' ? 'navbar-dark' : 'navbar-light'}`}>
                  <div className="justify-content-center">
                    <ul className="navbar-nav">
                      {this.props.network && this.state.address ? (
                        <>
                          <li className="nav-item pe-3 pt-2 border-end d-none d-lg-block">
                            <CopyToClipboard text={this.state.address}
                              onCopy={() => this.setCopied()}>
                              <span role="button"><span className={'small d-block clipboard' + (this.state.copied ? ' copied' : '')}>{this.state.address}</span></span>
                            </CopyToClipboard>
                          </li>
                          <li className="nav-item ps-3 pt-1">
                            <Dropdown as={ButtonGroup}>
                              <Dropdown.Toggle size="sm" className="rounded" id="dropdown-custom-1">
                                <Coins
                                  coins={this.state.balance}
                                  decimals={this.props.network.decimals}
                                  className="me-1"
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Header>{this.state.accountName || 'Wallet'}</Dropdown.Header>
                                <Dropdown.Item>
                                  <CopyToClipboard text={this.state.address}
                                    onCopy={() => this.setCopied()}>
                                    <span role="button"><span style={{ maxWidth: 200 }} className={'small d-block text-truncate clipboard' + (this.state.copied ? ' copied' : '')}>{this.state.address}</span></span>
                                  </CopyToClipboard>
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item onClick={this.disconnect}>Disconnect</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </li>
                        </>
                      ) : this.props.network && (
                        <>
                          <li className="nav-item ps-3">
                            <Button onClick={() => this.connect(true)} className="btn-sm">Connect</Button>
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="justify-content-between border-bottom d-block d-sm-none" style={{fontSize:'12px'}}>
                <Navbar className={`navbar navbar-expand ${this.props.theme === 'dark' ? 'navbar-dark' : 'navbar-light'}`}>
                  <div className="justify-content-center">
                    <Nav activeKey={this.props.active} onSelect={(e) => this.props.setActive(e)}>
                          <div className="nav-item border-end">
                            <Nav.Link eventKey="networks">
                              <Stars className="mb-1 me-1" />
                              <br />
                              Explore
                            </Nav.Link>
                          </div>
                      {this.props.network && (
                        <>
                          <div className="nav-item border-end">
                            <Nav.Link eventKey="delegations" style={{paddingRight:"0.2rem",paddingLeft:"0.2rem"}}>
                              <Coin className="mb-1 me-1" />
                              <br />
                              Delegate
                            </Nav.Link>
                          </div>
                          <div className="nav-item border-end">
                            <Nav.Link eventKey="governance" style={{paddingRight:"0.2rem",paddingLeft:"0.2rem"}}>
                              <Inboxes className="mb-1 me-1" />
                              <br />
                              Govern
                            </Nav.Link>
                          </div>
                          <div className="nav-item border-end">
                            <Nav.Link eventKey="delegatorcenter" style={{paddingRight:"0.2rem",paddingLeft:"0.2rem"}}>
                              <Person className="mb-1 me-1" /> 
                              <br />
                              Delegator Center
                            </Nav.Link>
                          </div>
                          <div className="nav-item border-end">
                            <Nav style={{paddingRight:"0.2rem",paddingLeft:"0.2rem"}}>
                              <a href={`https://twitter.com/spacefi_io/status/1536302354495987712`} target="_blank" className="nav-link text-decoration-none d-block" style={{padding:"0.5rem 0"}}>
                                <Star className="mb-1 me-1" /> 
                                <br />
                                Space Incentive
                              </a>
                            </Nav>
                          </div> 
                          <div className="nav-item">
                            <Nav.Link eventKey="compensation" style={{paddingRight:"0.2rem",paddingLeft:"0.2rem"}}>
                              {/* <Gift className="mb-1 me-1" />  */}
                              <img style={{width:'16px',height:'16px',margin:'0 4px 4px 0'}} src={Logo123} />
                              <br />
                              Compensation
                            </Nav.Link>
                          </div>
                        </>
                      )}
                    </Nav>
                  </div>
                </Navbar>
                <nav className={`navbar navbar-expand-lg justify-content-end ${this.props.theme === 'dark' ? 'navbar-dark' : 'navbar-light'}`}>
                  <div className="justify-content-center">
                    <ul className="navbar-nav">
                      {this.props.network && this.state.address ? (
                        <>
                          <li className="nav-item pe-3 pt-2 border-end d-none d-lg-block">
                            <CopyToClipboard text={this.state.address}
                              onCopy={() => this.setCopied()}>
                              <span role="button"><span className={'small d-block clipboard' + (this.state.copied ? ' copied' : '')}>{this.state.address}</span></span>
                            </CopyToClipboard>
                          </li>
                          <li className="nav-item ps-3 pt-1">
                            <Dropdown as={ButtonGroup}>
                              <Dropdown.Toggle size="sm" className="rounded" id="dropdown-custom-1">
                                <Coins
                                  coins={this.state.balance}
                                  decimals={this.props.network.decimals}
                                  className="me-1"
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Header>{this.state.accountName || 'Wallet'}</Dropdown.Header>
                                <Dropdown.Item>
                                  <CopyToClipboard text={this.state.address}
                                    onCopy={() => this.setCopied()}>
                                    <span role="button"><span style={{ maxWidth: 200 }} className={'small d-block text-truncate clipboard' + (this.state.copied ? ' copied' : '')}>{this.state.address}</span></span>
                                  </CopyToClipboard>
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item onClick={this.disconnect}>Disconnect</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </li>
                        </>
                      ) : this.props.network && (
                        <>
                          <li className="nav-item ps-3">
                            <Button onClick={() => this.connect(true)} className="btn-sm">Connect</Button>
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                </nav>
              </div>
            </>
          }
        </header>
        <div className="my-4">
          {this.props.network?.experimental && (
            <AlertMessage variant="info" dismissible={false}>
              This network was added to REStake automatically and has not been thoroughly tested yet. <a href="https://github.com/eco-stake/restake/issues" target="_blank">Raise an issue</a> if you have any problems.
            </AlertMessage>
          )}
          <AlertMessage message={this.state.error} variant="danger" dismissible={false} />
          {!this.state.address && this.props.network && (
            !this.state.keplr
              ? (
                <AlertMessage variant="warning" dismissible={false}>
                  Please install the <a href="https://chrome.google.com/webstore/detail/keplr/dmkamcknogkgcdfhhbddcghachkejeap?hl=en" target="_blank" rel="noreferrer">Keplr browser extension</a> using desktop Google Chrome.<br />WalletConnect and mobile support is coming soon.
                </AlertMessage>
              ) : this.props.active !== 'networks' && this.props.active != 'delegatorcenter' && this.props.active != 'compensation' && (
                <div className="mb-5 text-center">
                  <Button onClick={() => this.connect(true)} style={{margin:'5px'}}>
                    Connect Keplr
                  </Button>
                  <Button style={{margin:'5px'}}>
                    <Navbar style={{padding:'0',color:'#fff'}}>
                        <Nav activeKey={this.props.active} onSelect={(e) => this.props.setActive(e)} style={{padding:'0',color:'#fff'}}>
                            <>
                              <div>
                                <Nav.Link eventKey="delegatorcenter" style={{padding:'0',color:'#fff'}}>
                                Delegator Center
                                </Nav.Link>
                              </div>
                            </>
                        </Nav>
                    </Navbar>
                  </Button>
                  {/* <Button onClick={() => window.location.href = `http://localhost:1234/evmos/delegatorcenter`}  style={{margin:'5px'}}> 
                    Check Delegator Points
                  </Button> */}
                  <p style={{marginTop:'20px'}}>You can search address to check delegator points or claim roles with Keplr in Delegator Center. Metamask delegators can claim roles in <a href="https://chrome.google.com/webstore/detail/keplr/dmkamcknogkgcdfhhbddcghachkejeap?hl=en" target="_blank" rel="noreferrer" style={{color:'#373a3c'}}>https://guild.xyz/spacefi</a></p>
                </div>
              )
          )}
          {this.props.active === 'networks' && (
            <Networks 
              networks={Object.values(this.props.networks)} 
              changeNetwork={this.props.changeNetwork} 
              favourites={this.state.favourites} 
              toggleFavourite={this.toggleFavourite} />
          )}
          {this.props.active === 'governance' && this.state.address && (
          <Governance
            network={this.props.network}
            address={this.state.address}
            queryClient={this.state.queryClient}
            stargateClient={this.state.stargateClient} />
          )}
          {this.props.active === 'delegatorcenter' && (
          <Delegatorcenter
            integra={this.state.integra}
            integraList={this.state.integraList}
            integraListPages={this.state.integraListPages}
            allIntegraNum={this.state.allIntegraNum}
            network={this.props.network}
            address={this.state.address}
            balance={this.state.balance}
            operators={this.props.operators}
            validators={this.props.validators}
            validator={this.props.validator}
            getBalance={this.getBalance}
            connect={this.connect}
            queryClient={this.state.queryClient}
            stargateClient={this.state.stargateClient} />
          )}
          {this.props.active === 'delegations' && this.state.address &&
            <>
              <Delegations
                network={this.props.network}
                address={this.state.address}
                balance={this.state.balance}
                operators={this.props.operators}
                validators={this.props.validators}
                validator={this.props.validator}
                getBalance={this.getBalance}
                queryClient={this.state.queryClient}
                stargateClient={this.state.stargateClient} />
            </>
          }
          {this.props.active === 'compensation' && (
          <Compensation
            getKeplrDelegator={this.getKeplrDelegator}
            keplrDelegator={this.state.keplrDelegator}
            changeMetamask={this.changeMetamask}
            metamaskType={this.state.metamaskType}
            metamaskAddress={this.state.metamaskAddress}
            integra={this.state.integra}
            integraList={this.state.integraList}
            integraListPages={this.state.integraListPages}
            allIntegraNum={this.state.allIntegraNum}
            network={this.props.network}
            address={this.state.address}
            balance={this.state.balance}
            operators={this.props.operators}
            validators={this.props.validators}
            validator={this.props.validator}
            getBalance={this.getBalance}
            connect={this.connect}
            queryClient={this.state.queryClient}
            stargateClient={this.state.stargateClient} />
          )}
          <hr />
          <p className="mt-5 text-center">
            Enabling REStake will authorize the validator to send <em>Delegate</em> transactions on your behalf for 1 year <a href="https://docs.cosmos.network/master/modules/authz/" target="_blank" rel="noreferrer" className="text-reset">using Authz</a>.<br />
            They will only be authorized to delegate to their own validator. You can revoke the authorization at any time and everything is open source.
          </p>
          <p className="text-center mb-4">
            <strong>The validators will pay the transaction fees for you.</strong>
          </p>
          <p className="text-center mb-5">
            <Button onClick={() => this.setState({ showAbout: true })} variant="outline-secondary">More info</Button>
          </p>
        </div>
        <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
          <a href="https://akash.network" target="_blank" rel="noreferrer" className="col-md-4 mb-0 text-muted">
            {this.props.theme === 'light'
              ? (
                <img src={PoweredByAkash} alt="Powered by Akash" width={200} />
              ) : (
                <img src={PoweredByAkashWhite} alt="Powered by Akash" width={200} />
              )}
          </a>
          <div className="col-md-4 align-items-center text-center me-lg-auto">
            <a href="https://ecostake.com" target="_blank" rel="noreferrer" className="text-reset text-decoration-none d-block mb-2">
              <span className="d-none d-sm-inline">Built with 💚&nbsp;</span> by ECO Stake 🌱
            </a>
            <a href={`https://${directoryDomain}`} target="_blank" className="text-reset text-decoration-none d-block small">
              <span className="d-none d-sm-inline">Interchain APIs from</span> <u>cosmos.directory</u>
            </a>
          </div>
          <p className="col-md-4 mb-0 text-muted text-end justify-content-end d-none d-lg-flex">
            {this.props.theme === 'light'
              ? (
                <GitHubButton href="https://github.com/eco-stake/restake" data-icon="octicon-star" data-size="large" data-show-count="true" aria-label="Star eco-stake/restake on GitHub">Star</GitHubButton>
              ) : (
                <GitHubButton href="https://github.com/eco-stake/restake" data-icon="octicon-star" data-size="large" data-show-count="true" aria-label="Star eco-stake/restake on GitHub" data-color-scheme="no-preference: dark; light: dark; dark: dark;">Star</GitHubButton>
              )}
          </p>
        </footer>
        <About show={this.state.showAbout} onHide={() => this.setState({ showAbout: false })} />
      </Container>
    )
  }
}

export default App;
