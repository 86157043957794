'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _util = require('./util');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

(function () {
  var enterModule = (typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal : require('react-hot-loader')).enterModule;
  enterModule && enterModule(module);
})();

var reInitChecked = function reInitChecked(node) {
  var siblings = node.childNodes;

  var all = true;
  var none = true;

  for (var i = 0, j = siblings.length; i < j; i++) {
    var sibling = siblings[i];
    if (sibling.checked !== true || sibling.indeterminate) {
      all = false;
    }
    if (sibling.checked !== false || sibling.indeterminate) {
      none = false;
    }
  }

  if (all) {
    node.setChecked(true);
  } else if (!all && !none) {
    node.setChecked('half');
  } else if (none) {
    node.setChecked(false);
  }
};

var getPropertyFromData = function getPropertyFromData(node, prop) {
  var props = node.store.props;
  var data = node.data || {};
  var config = props[prop];

  if (typeof config === 'function') {
    return config(data, node);
  } else if (typeof config === 'string') {
    return data[config];
  } else if (typeof config === 'undefined') {
    return '';
  }
};

var nodeIdSeed = 0;

var Node = function () {
  function Node(options) {
    (0, _classCallCheck3.default)(this, Node);

    this.id = nodeIdSeed++;
    this.text = null;
    this.checked = false;
    this.indeterminate = false;
    this.data = null;
    this.expanded = false;
    this.parent = null;
    this.visible = true;

    for (var name in options) {
      if (options.hasOwnProperty(name)) {
        this[name] = options[name];
      }
    }

    // internal
    this.level = 0;
    this.loaded = false;
    this.childNodes = [];
    this.loading = false;

    if (this.parent) {
      this.level = this.parent.level + 1;
    }

    var store = this.store;
    if (!store) {
      throw new Error('[Node]store is required!');
    }
    store.registerNode(this);

    var props = store.props;
    if (props && typeof props.isLeaf !== 'undefined') {
      var isLeaf = getPropertyFromData(this, 'isLeaf');
      if (typeof isLeaf === 'boolean') {
        this.isLeafByUser = isLeaf;
      }
    }

    if (store.lazy !== true && this.data) {
      this.setData(this.data);

      if (store.defaultExpandAll) {
        this.expanded = true;
      }
    } else if (this.level > 0 && store.lazy && store.defaultExpandAll) {
      this.expand();
    }

    if (!this.data) return;
    var defaultExpandedKeys = store.defaultExpandedKeys;
    var key = store.key;
    if (key && defaultExpandedKeys && defaultExpandedKeys.indexOf(this.key) !== -1) {
      this.expand(null, store.autoExpandParent);
    }

    if (key && store.currentNodeKey && this.key === store.currentNodeKey) {
      store.currentNode = this;
    }

    if (store.lazy) {
      store._initDefaultCheckedNode(this);
    }

    this.updateLeafState();
  }

  (0, _createClass3.default)(Node, [{
    key: 'setData',
    value: function setData(data) {
      if (!Array.isArray(data)) {
        (0, _util.markNodeData)(this, data);
      }

      this.data = data;
      this.childNodes = [];

      var children = void 0;
      if (this.level === 0 && this.data instanceof Array) {
        children = this.data;
      } else {
        children = getPropertyFromData(this, 'children') || [];
      }

      for (var i = 0, j = children.length; i < j; i++) {
        this.insertChild({ data: children[i] });
      }
    }
  }, {
    key: 'insertChild',
    value: function insertChild(child, index) {
      if (!child) throw new Error('insertChild error: child is required.');

      if (!(child instanceof Node)) {
        Object.assign(child, {
          parent: this,
          store: this.store
        });
        child = new Node(child);
      }

      child.level = this.level + 1;

      if (typeof index === 'undefined' || index < 0) {
        this.childNodes.push(child);
      } else {
        this.childNodes.splice(index, 0, child);
      }

      this.updateLeafState();
    }
  }, {
    key: 'insertBefore',
    value: function insertBefore(child, ref) {
      var index = void 0;
      if (ref) {
        index = this.childNodes.indexOf(ref);
      }
      this.insertChild(child, index);
    }
  }, {
    key: 'insertAfter',
    value: function insertAfter(child, ref) {
      var index = void 0;
      if (ref) {
        index = this.childNodes.indexOf(ref);
        if (index !== -1) index += 1;
      }
      this.insertChild(child, index);
    }
  }, {
    key: 'removeChild',
    value: function removeChild(child) {
      var index = this.childNodes.indexOf(child);

      if (index > -1) {
        this.store && this.store.deregisterNode(child);
        child.parent = null;
        this.childNodes.splice(index, 1);
      }

      this.updateLeafState();
    }
  }, {
    key: 'removeChildByData',
    value: function removeChildByData(data) {
      var targetNode = null;
      this.childNodes.forEach(function (node) {
        if (node.data === data) {
          targetNode = node;
        }
      });

      if (targetNode) {
        this.removeChild(targetNode);
      }
    }
  }, {
    key: 'expand',
    value: function expand(callback, expandParent) {
      var _this = this;

      var done = function done() {
        if (expandParent) {
          var parent = _this.parent;
          while (parent.level > 0) {
            parent.expanded = true;
            parent = parent.parent;
          }
        }
        _this.expanded = true;
        if (callback) callback();
      };

      if (this.shouldLoadData()) {
        this.loadData(function (data) {
          if (data instanceof Array) {
            done();
          }
        });
      } else {
        done();
      }
    }
  }, {
    key: 'doCreateChildren',
    value: function doCreateChildren(array) {
      var _this2 = this;

      var defaultProps = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      array.forEach(function (item) {
        _this2.insertChild(Object.assign({ data: item }, defaultProps));
      });
    }
  }, {
    key: 'collapse',
    value: function collapse() {
      this.expanded = false;
    }
  }, {
    key: 'shouldLoadData',
    value: function shouldLoadData() {
      return this.store.lazy === true && this.store.load && !this.loaded;
    }
  }, {
    key: 'updateLeafState',
    value: function updateLeafState() {
      if (this.store.lazy === true && this.loaded !== true && typeof this.isLeafByUser !== 'undefined') {
        this.isLeaf = this.isLeafByUser;
        return;
      }
      var childNodes = this.childNodes;
      if (!this.store.lazy || this.store.lazy === true && this.loaded === true) {
        this.isLeaf = !childNodes || childNodes.length === 0;
        return;
      }
      this.isLeaf = false;
    }
  }, {
    key: 'setChecked',
    value: function setChecked(value, deep) {
      var _this3 = this;

      this.indeterminate = value === 'half';
      this.checked = value === true;

      var handleDescendants = function handleDescendants() {
        if (deep) {
          var childNodes = _this3.childNodes;
          for (var i = 0, j = childNodes.length; i < j; i++) {
            var child = childNodes[i];
            child.setChecked(value !== false, deep);
          }
        }
      };

      if (!this.store.checkStrictly && this.shouldLoadData()) {
        // Only work on lazy load data.
        this.loadData(function () {
          handleDescendants();
        }, {
          checked: value !== false
        });
      } else {
        handleDescendants();
      }

      var parent = this.parent;
      if (!parent || parent.level === 0) return;

      if (!this.store.checkStrictly) {
        reInitChecked(parent);
      }
    }
  }, {
    key: 'getChildren',
    value: function getChildren() {
      // this is data
      var data = this.data;
      if (!data) return null;

      var props = this.store.props;
      var children = 'children';
      if (props) {
        children = props.children || 'children';
      }

      if (data[children] === undefined) {
        data[children] = null;
      }

      return data[children];
    }
  }, {
    key: 'updateChildren',
    value: function updateChildren() {
      var _this4 = this;

      var newData = this.getChildren() || [];
      var oldData = this.childNodes.map(function (node) {
        return node.data;
      });

      var newDataMap = {};
      var newNodes = [];

      newData.forEach(function (item, index) {
        if (item[_util.NODE_KEY]) {
          newDataMap[item[_util.NODE_KEY]] = { index: index, data: item };
        } else {
          newNodes.push({ index: index, data: item });
        }
      });

      oldData.forEach(function (item) {
        if (!newDataMap[item[_util.NODE_KEY]]) _this4.removeChildByData(item);
      });

      newNodes.forEach(function (_ref) {
        var index = _ref.index,
            data = _ref.data;

        _this4.insertChild({ data: data }, index);
      });

      this.updateLeafState();
    }
  }, {
    key: 'loadData',
    value: function loadData(callback) {
      var _this5 = this;

      var defaultProps = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      if (this.store.lazy === true && this.store.load && !this.loaded && !this.loading) {
        this.loading = true;

        var resolve = function resolve(children) {
          _this5.loaded = true;
          _this5.loading = false;
          _this5.childNodes = [];

          _this5.doCreateChildren(children, defaultProps);

          _this5.updateLeafState();
          if (callback) {
            callback.call(_this5, children);
          }
        };

        this.store.load(this, resolve);
      } else {
        if (callback) {
          callback.call(this);
        }
      }
    }
  }, {
    key: '__reactstandin__regenerateByEval',
    // @ts-ignore
    value: function __reactstandin__regenerateByEval(key, code) {
      // @ts-ignore
      this[key] = eval(code);
    }
  }, {
    key: 'label',
    get: function get() {
      return getPropertyFromData(this, 'label');
    }
  }, {
    key: 'icon',
    get: function get() {
      return getPropertyFromData(this, 'icon');
    }
  }, {
    key: 'key',
    get: function get() {
      var nodeKey = this.store.key;
      if (this.data) return this.data[nodeKey];
      return null;
    }
  }]);
  return Node;
}();

var _default = Node;
exports.default = _default;
;

(function () {
  var reactHotLoader = (typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal : require('react-hot-loader')).default;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(reInitChecked, 'reInitChecked', 'src/tree/model/node.js');
  reactHotLoader.register(getPropertyFromData, 'getPropertyFromData', 'src/tree/model/node.js');
  reactHotLoader.register(nodeIdSeed, 'nodeIdSeed', 'src/tree/model/node.js');
  reactHotLoader.register(Node, 'Node', 'src/tree/model/node.js');
  reactHotLoader.register(_default, 'default', 'src/tree/model/node.js');
})();

;

(function () {
  var leaveModule = (typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal : require('react-hot-loader')).leaveModule;
  leaveModule && leaveModule(module);
})();