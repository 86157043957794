[
  {
    "name": "osmosis",
    "gasPrice": "0.0025uosmo",
    "ownerAddress": "osmovaloper1u5v0m74mql5nzfx2yh43s2tke4mvzghr6m2n5t",
    "default": true
  },
  {
    "name": "juno",
    "gasPrice": "0.0025ujuno"
  },
  {
    "name": "cosmoshub",
    "gasPrice": "0.0025uatom"
  },
  {
    "name": "akash",
    "ownerAddress": "akashvaloper1xgnd8aach3vawsl38snpydkng2nv8a4kqgs8hf"
  },
  {
    "name": "chihuahua",
    "gasPrice": "0.025uhuahua",
    "ownerAddress": "chihuahuavaloper19vwcee000fhazmpt4ultvnnkhfh23ppwxll8zz"
  },
  {
    "name": "gravitybridge",
    "gasPrice": "0.025ugraviton"
  },
  {
    "name": "regen",
    "gasPrice": "0.03uregen",
    "ownerAddress": "regenvaloper1c4y3j05qx652rnxm5mg4yesqdkmhz2f6dl7hhk",
    "autostake": {
      "batchTxs": 5
    }
  },
  {
    "name": "terra",
    "apyEnabled": false,
    "gasPrice": "0.015uluna"
  },
  {
    "name": "sentinel",
    "gasPrice": "0.02udvpn"
  },
  {
    "name": "dig",
    "gasPrice": "0.01udig",
    "ownerAddress": "digvaloper136avwnuvvy94dqmtnaue2nfvjes8xr37h9rzay"
  },
  {
    "name": "bitcanna",
    "gasPrice": "0.001ubcna"
  },
  {
    "name": "emoney",
    "apyEnabled": false,
    "gasPrice": "0.08ungm"
  },
  {
    "name": "kava",
    "apyEnabled": false,
    "gasPrice": "0.00008ukava"
  },
  {
    "name": "desmos",
    "gasPrice": "0.001udsm"
  },
  {
    "name": "cryptoorgchain",
    "gasPrice": "0.025basecro",
    "ownerAddress": "crocncl10mfs428fyntu296dgh5fmhvdzrr2stlaekcrp9"
  },
  {
    "name": "evmos",
    "apyEnabled": false,
    "txTimeout": 120000,
    "ownerAddress": "evmosvaloper18zhvafj8jcezdut6z9u0pcsnqy978ya9d25du5"
  },
  {
    "name": "sifchain",
    "gasPrice": "1500000000000rowan",
    "ownerAddress": "sifvaloper19t5nk5ceq5ga75epwdqhnupwg0v9339p096ydz"
  },
  {
    "name": "lumnetwork"
  },
  {
    "name": "stargaze",
    "apyEnabled": false
  },
  {
    "name": "comdex",
    "ownerAddress": "comdexvaloper17f70yjkvmvld379904jaddx9h0f74n32pjtmp6"
  },
  {
    "name": "cheqd",
    "gasPrice": "25ncheq"
  },
  {
    "name": "umee"
  },
  {
    "name": "bitsong"
  },
  {
    "name": "persistence"
  },
  {
    "name": "agoric",
    "apyEnabled": false
  },
  {
    "name": "impacthub",
    "gasPrice": "0.025uixo",
    "gasPricePrefer": "0.1uixo"
  },
  {
    "name": "kichain",
    "gasPrice": "0.025uxki"
  },
  {
    "name": "sommelier"
  },
  {
    "name": "konstellation",
    "image": "https://raw.githubusercontent.com/Konstellation/DARC_token/main/darctoken.svg"
  },
  {
    "name": "fetchhub",
    "gasPrice": "5000afet"
  },
  {
    "name": "cerberus",
    "gasPrice": "0.025ucrbrus",
    "autostake": {
      "batchTxs": 100
    },
    "ownerAddress": "cerberusvaloper1tat2cy3f9djtq9z7ly262sqngcarvaktr0w78f"
  },
  {
    "name": "secretnetwork",
    "gasPrice": "0.025uscrt",
    "gasPricePrefer": "0.05uscrt"
  },
  {
    "name": "bostrom",
    "gasPrice": "0boot"
  },
  {
    "name": "starname",
    "gasPrice": "10uvoi"
  },
  {
    "name": "rizon",
    "gasPrice": "0.0001uatolo"
  },
  {
    "name": "decentr",
    "gasPrice": "0.025udec"
  },
  {
    "name": "assetmantle",
    "gasPrice": "0.025umntl",
    "gasPriceStep": {
      "low": 0,
      "average": 0.025,
      "high": 0.04
    },
    "ownerAddress": "mantlevaloper1fqs7gakxdmujtk0qufdzth5pfyspus3yx394zd"
  },
  {
    "name": "crescent",
    "gasPrice": "0.025ucre",
    "gasPriceStep": {
      "low": 0,
      "average": 0.025,
      "high": 0.04
    },
    "apyEnabled": false
  },
  {
    "name": "meme",
    "gasPrice": "0.025umeme"
  },
  {
    "name": "cronos",
    "enabled": false
  }
]
