import React, { useEffect } from 'react';
import {
  Modal
} from 'react-bootstrap'

import { useSearchParams } from 'react-router-dom'
function About(props) {
  let [searchParams, setSearchParams] = useSearchParams();
  const aboutParam = searchParams.get('about')

  const show = props.show || aboutParam == 'Points'

  useEffect(() => {
    if (show && !aboutParam) {
      setSearchParams({ about: 'Points' })
    }
  }, [show, aboutParam]);

  function onHide(){
    setSearchParams({})
    props.onHide()
  }

  return (
    <>
      <Modal show={show} onHide={() => onHide()}>
        <Modal.Header closeButton>
          <Modal.Title>About Delegator Points</Modal.Title>
        </Modal.Header>
        <Modal.Body className="small">
          <h5>What is Delegator Point:</h5>
          <p>Delegator Points is an evaluation of contribution, based on your staked amount, time and governance participation. </p>
          <p>Delegator Points = staked amount A * A days / 10 + staked amountB * B days + … + number of voting * 100</p>
          <p>eg. You staked 1000 $EVMOS for 30days, and staked another 500 $EVMOS for 25days. Also, you voted twice on governance.</p>
          <p>Your Delegator Points = 1000*30/10+500*25/10+2*100=4450</p>
          <h5>Use of Validator Points: </h5>
          <p>It can be used in validator incentive such as airdrop, WL and NFT. What's more, some interesting ideas such as delegator NFT, soulbound are in cooking. Let's co-build it.</p>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default About
