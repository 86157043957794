import React, { useState, useEffect , useReducer} from 'react';
import _ from 'lodash'
import FuzzySearch from 'fuzzy-search'
import 'element-theme-default';
import React from 'react';
import { Button, } from "react-bootstrap";

import $ from "jquery";

function Validators(props) {
  const {  network, validators, operators, delegations, operatorGrants, claimDelegator, address, getKeplrDelegator} = props
  const [filter, setFilter] = useState({keywords: '', status: 'active', group: 'delegated'})
  const [results, setResults] = useState([])
  const [state, setState] = useReducer(
    (state, newState) => ({...state, ...newState}),
    {loading: true, networks: {}, operators: [], validators: {}, showAboutDelegator: false, searchValue:'',discordName:''}
  )
  useEffect(() => {
    let filtered = filteredValidators(validators, filter)
    let group = filter.group
    while(filtered.length < 1 && group !== 'all'){
      group = group === 'delegated' ? 'operators' : 'all'
      filtered = filteredValidators(validators, {...filter, group})
      if(filtered.length > 0 || group === 'all'){
        return setFilter({ ...filter, group })
      }
    }
    setResults(filtered)
  }, [validators, operators, delegations, operatorGrants, filter]);
  
  function sortValidators(validators){
    validators = _.sortBy(validators, ({ operator_address: address }) => {
      const delegation = delegations && delegations[address]
      return 0 - (delegation?.balance?.amount || 0)
    });
    return _.sortBy(validators, ({ operator_address: address }) => {
      if(network.data.ownerAddress === address) return -5

      const delegation = delegations && delegations[address]
      const operator = operators && operators.find(el => el.address === address)

      if (delegation) {
        return operator ? -2 : -1
      } else {
        return operator ? 0 : 1
      }
    });
  }
  function filteredValidators(validators, filter){
    let searchResults
    if (props.exclude){
      searchResults = Object.values(_.omit(validators, props.exclude))
    }else{
      searchResults = Object.values(validators)
    }
    const { keywords, status, group } = filter

    if(status){
      searchResults = searchResults.filter(result => {
        if (status === 'active') {
          return result.status === 'BOND_STATUS_BONDED'
        } else if (status === 'inactive') {
          return result.status !== 'BOND_STATUS_BONDED'
        } else {
          return true
        }
      })
    }

    searchResults = filterByGroup(searchResults, group)

    if (!keywords || keywords === '') return sortValidators(searchResults)

    const searcher = new FuzzySearch(
      searchResults, ['description.moniker'],
      { sort: true }
    )

    return searcher.search(keywords)
  }

  function filterByGroup(validators, group){
    switch (group) {
      case 'delegated':
        validators = validators.filter(({operator_address: address}) => {
          return delegations && delegations[address]
        })
        break;
      case 'operators':
        validators = validators.filter(({operator_address: address}) => {
          return operators && operators.find(el => el.address === address)
        })
        break;
    }
    return validators
  }
  return (
    <>
      {
        !address
        ? 
          <Button onClick={() => {
            props.connect(true)
          }} style={{minWidth:"200px"}}>
            Connect Keplr
          </Button>
        : props.keplrDelegator == null
          ? 
            <div>
              <p>No compensation found in your Keplr delegator address</p>
            </div>
          :
            <>
              <div className="d-sm-none d-sm-flex" style={{display:'flex',flexWrap:"wrap",alignItems:'center'}}>
                <div style={{width:"33%",textAlign:'center'}}>
                  <p>Your Compensation</p>
                  <p>{props.keplrDelegator.loss_money}</p>
                </div>
                <div style={{width:"33%",textAlign:'center'}}>
                  <p>Claimed</p>
                  <p>{props.keplrDelegator.claimed_money}</p>
                </div>
                <div style={{width:"33%",textAlign:'center'}}>
                  <p>Pending</p>
                  <p>{props.keplrDelegator.pending_money}</p>
                </div>
                <div style={{width:"100%",textAlign:'center'}}>
                  <Button onClick={(e) => {
                    claimDelegator(address,0)
                  }} style={{minWidth:'100%'}}>Claim</Button>
                </div>
              </div>
              <div className="d-none d-sm-flex" style={{display:'flex',alignItems:'center'}}>
                <div style={{flex:'2',textAlign:'center'}}>
                  <p>Your Compensation</p>
                  <p>{props.keplrDelegator.loss_money}</p>
                </div>
                <div style={{flex:'2',textAlign:'center'}}>
                  <p>Claimed</p>
                  <p>{props.keplrDelegator.claimed_money}</p>
                </div>
                <div style={{flex:'2',textAlign:'center'}}>
                  <p>Pending</p>
                  <p>{props.keplrDelegator.pending_money}</p>
                </div>
                <div style={{flex:'2',textAlign:'center'}}>
                  <Button onClick={(e) => {
                    claimDelegator(address,0)
                  }} style={{minWidth:'100px'}}>Claim</Button>
                </div>
              </div>
            </>
      }
    </>
  )
}

export default Validators;
